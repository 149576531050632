import React from 'react'
import VisibilitySensor from 'react-visibility-sensor';
import './cclStats.scss';

const stats = [
  {
    id: 'inspired',
    stat: '96%',
    about: 'Of our participants say they’re stronger leaders'
  },
  {
    id: 'alumni',
    stat: '10+',
    about: 'Virtual programs for individual growth',
    link: 'https://www.ccl.org/lead-it-yourself-solutions/digital-learning/'
  },
  {
    id: 'served',
    stat: '80k',
    about: 'People shared their journey with us last year'
  }
];

const animateValue = (id, endString, end) => {
  let range = end - 0;
  let current = 0;
  let increment = end > 0 ? 1 : -1;
  let stepTime = Math.abs(Math.floor(1000 / range));
  let obj = document.getElementById(id);

  let timer = setInterval(function () {
    current += increment;
    obj.innerHTML = current + endString;
    if (current === end) {
      clearInterval(timer);
    }
  }, stepTime);
}

let counted = false;

const onVisible = isVisible => {
  if (isVisible && !counted) {
    animateValue('inspired', '%', 96);
    animateValue('served', 'K', 80);
    counted = true;
  }
}

const openLink = link => {
  if (link) {
    window.open(link, '_blank');
  }
}

const IndividualStats = ({ isVisible }) => {

  return (
    <div className="ccl-stats">
      <div className="stats">
        <h2 className="title">
          Every challenge presents the opportunity for you to do more and be more.  And the challenges of today are unprecedented.  Are you ready to evolve, adapt, and embrace change as a catalyst for growth?
        </h2>
        <VisibilitySensor onChange={onVisible}>
          <div className="stats-content subpage">
            {stats.map(stat => (
              <div className={`ccl-stat ${stat.link ? 'pointer' : ''}`} key={stat.id} onClick={() => openLink(stat.link)}>
                <h2 id={stat.id}>{stat.stat}</h2>
                <h4 className={stat.link ? 'underlined' : ''}>{stat.about}</h4>
              </div>
            ))}
          </div>
        </VisibilitySensor>
      </div>
    </div>
  );
};

export default IndividualStats;
