import React from 'react';
import '../styles/global.scss';
import Layout from '../components/layout';
import CCLLeadspace from '../components/cclLeadspace';
import IndividualStats from './partials/individualsStats';
import CardSlider from '../components/cardSlider';
import JourneyForm from '../components/journeyForm';
import CCLButton from '../components/cclButton';
import CCLExplore from '../components/cclExplore';
import '../styles/global.scss';
import LeadImage from '../images/individuals-lead.jpg';
import SEO from '../components/seo';
import Story2 from '../images/stories/individual2.jpg';
import Story4 from '../images/stories/individual4.png';
import Story5 from '../images/stories/individual5.png';
import Story6 from '../images/stories/individual6.png';

const stories = [
  {
    image: Story5,
    title: 'Find your strengths. And find yourself along the way.',
    cta: 'view alumni success story',
    expanded: {
      title: 'Advocate. Entrepreneur. Lives authentically.',
      subtitle: 'CCL will help you find your strengths. And find yourself along the way.',
      contentTitle: '',
      link: '',
      content:
        '<p>Andrea Pilon Artman came to CCL hoping to improve her leadership skills. But she left with so much more than that &mdash; a whole new perspective on who she is.</p><p>The simulated scenario within the Leading for Organizational Impact program she attended was designed to reveal each person’s true leadership characteristics. Andrea was surprised to learn that her heart wasn’t in the career path she’d been pursuing. With the support and encouragement of her group, she shed the mask she felt she’d been wearing and let her true self show. Newly inspired to live authentically, Andrea left her job to begin her own medical device consultancy as well as pursue a passionate advocacy for educational, social, and economic equality of all persons living within her community and beyond.</p>'
    }
  },
  {
    image: Story6,
    title: 'The journey for every leader is unique. But no one is alone.',
    cta: 'view alumni success story',
    expanded: {
      title: 'Builder. Visionary. Never Gives Up.',
      subtitle: 'Every leadership journey has twists and turns. CCL will guide you along the way.',
      contentTitle: '',
      link: '',
      content:
        '<p>In the early 2000’s, Dan Eckert was flying high as president and COO of a fast-growing “dot com” business. The future looked bright &mdash; until the market crashed. After years of painstaking survival, a merger rescued the business. Dan found himself in a new leadership role at a new business and in need of guidance on how to move forward as a leader in this next phase of his career.</p><p>The new company’s CEO saw great potential in Dan and sent him to CCL’s Leadership at The Peak program. Here, Dan recognized something transformational: the journey for every leader is unique, but no one is alone. Sharing and collaborating with others along the way makes everyone stronger. Dan awoke to his own resilience and the value of compassion and humbling self-awareness. And, he learned how to channel those attributes into building, inspiring, and mentoring a team. With CCL’s help, Dan discovered that a bend in the road can lead to even greater opportunities.</p>'
    }
  }
];

const stories2 = [
  {
    image: Story4,
    title: 'Owning Your Story. Create impact by acting with intention.',
    cta: 'view alumni success story',
    expanded: {
      title: 'Technologist. Storyteller. Rising above.',
      subtitle: 'Grow your passion. Grow your team. Grow yourself, with CCL.',
      contentTitle: '',
      link: '',
      content:
        '<p>David Mascarina tells stories for a living. As the manager of digital content and creative at the Conrad N. Hilton Foundation, David gets to use his skills for good, partnering with some of the most brilliant minds solving humanitarian issues and getting to be creative all at the same time. At heart, David is also a problem-solver, always seeking ways to improve how his team and organization works.</p><p>But it’s not always easy to navigate complex work politics to get your voice heard. Attending CCL’s Leadership Development Program (LDP)® helped David find the right way to use his voice and elevate his relationship with his colleagues. The program also taught him the value of managing and acting with intention, taking a more proactive approach to his leadership style and truly understanding how he can impact the big picture goals of the Foundation. All it took was a chance to rise above the day-to-day to discover his own leadership story &mdash; and it’s looking like a big success.</p>'
    }
  },
  {
    image: Story2,
    title: 'Adopt the role of student. You’re never done learning.',
    cta: 'view alumni success story',
    expanded: {
      title: 'Mentor. Educator. Lifelong Student.',
      subtitle: 'You’re never done learning to be a leader. CCL is never done teaching.',
      contentTitle: '',
      link: '',
      content:
        '<p>Dr. Ashauna Harris may be the principal of a high-needs middle school, but she willingly adopted the role of student with CCL. As an educator, she sees herself as a mentor and role model for both her teachers and her students, an effort that often left her feeling stretched thin and not able to give either the attention they deserved.</p><p>Attending CCL’s Leadership Development Program (LDP)® gave Dr. Harris the coaching she needed to not only establish meaningful relationships in her school community, but also pass along what she learned to her teachers. United in purpose and driven to make a difference, Dr. Harris and her teachers are able to make that much more of a positive impact in the lives of their students.</p>'
    }
  }
]

let cards = [
  {
    header: 'Explore the latest thinking from CCL',
    subheader: '',
    cards: [
      {
        title: 'How to Lead Through a Crisis',
        link: 'https://www.ccl.org/articles/leading-effectively-articles/how-to-lead-through-a-crisis/',
        label: 'Read Article'
      },
      {
        title: 'Leadership Resiliency: Handling Stress, Uncertainty, and Setbacks',
        link: 'https://www.ccl.org/articles/leading-effectively-articles/leadership-resiliency-handling-stress-uncertainty-and-setbacks/',
        label: 'Read Article'
      },
      {
        title: 'Disruptive Trends Report: Talent Reimagined 2020',
        link: 'https://www.ccl.org/articles/research-reports/talent-reimagined-disruptive-trends-2020/',
        label: 'Download Report'
      },
      {
        title: 'The Core Leadership Skills You Need in Every Role',
        link: 'https://www.ccl.org/articles/leading-effectively-articles/fundamental-4-core-leadership-skills-for-every-career-stage/',
        label: 'Read Article'
      },
      {
        title: 'Building More Resilient Leadership for Greater Impact',
        link: 'https://www.ccl.org/webinars/building-more-resilient-leadership-for-greater-impact/',
        label: 'Watch Recording'
      },
      {
        title: 'How to Be a Successful Change Leader',
        link: 'https://www.ccl.org/articles/leading-effectively-articles/successful-change-leader/',
        label: 'Read Article'
      },
      {
        title: 'Learn Virtually Anywhere with Leaders from Around the World',
        link: 'https://www.ccl.org/lead-it-yourself-solutions/digital-learning/',
        label: 'View Programs'
      }
    ]
  }
];

const toggleStory = (s) => {
  const stories = Array.prototype.slice.call(document.querySelectorAll(".open"), 0);
  for (let index = 0; index < stories.length; index++) {
    const story = stories[index];
    story.classList.remove('open');
  }
  let openedStory = document.getElementById('story' + s);
  openedStory.classList.add('open');
  let openSection;
  if (window.innerWidth > 900) {
    openSection = document.getElementById('card' + s);
    openSection.classList.add('open');
  }
  openedStory.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

const closeStory = () => {
  const stories = Array.prototype.slice.call(document.querySelectorAll('.open'), 0);
  for (let i = 0; i < stories.length; i++) {
    const story = stories[i];
    story.classList.remove('open');
  }
}

const storyContent = (content, x) => {
  let storyLink = content.link ? true : false;
  let openHeadline = content.title.split('.');
  return (
    <div className="explore-open" id={'story' + x}>
      <div className="close-story" onClick={closeStory}>
        <span>X</span>
      </div>
      <h2>{openHeadline[0] + '.'}</h2>
      <h2>{openHeadline[1] + '.'}</h2>
      <h2>{openHeadline[2] + '.'}</h2>
      <h3>{content.subtitle}</h3>
      <span dangerouslySetInnerHTML={{ __html: content.content }}></span>
      {storyLink && (
        <a href={content.link} target="_blank">
          <CCLButton style="primary" label="Read the full case study" arrow="true" />
        </a>
      )}
    </div>
  )
}

const Individuals = () => (
  <Layout>
    <SEO
      title="Individuals | Innovative Leadership"
      description="A good leader prepares for tomorrow. Start your innovative leadership journey with us today."
      meta={{
        imgAlt: 'A woman in an innovative leadership role intently focuses on transforming her career with CCL. '
      }}
    />
    <CCLLeadspace image={LeadImage} copy="Leader.  Change Agent.  Ready for tomorrow." />
    <IndividualStats />
    <h2 className="stories-header">Meet a few of our talented alumni and read their success stories.</h2>
    <div className="desktop-stories">
      <div className="stories">
        {stories.map((link, i) => (
          <span
            key={link.title}
            onClick={() => {
              toggleStory(i);
            }}
          >
            <CCLExplore className="story" label={link.title} cardId={'card' + i} cta={link.cta} image={link.image} expandedContent={true}></CCLExplore>
          </span>
        ))}
      </div>

      <div className="openStories">
        {stories.map((story, x) => (
          <React.Fragment key={story.title}>{storyContent(story.expanded, x)}</React.Fragment>
        ))}
      </div>

      <div className="stories">
        {stories2.map((link, i) => (
          <span
            key={link.title}
            onClick={() => {
              toggleStory(i + 2);
            }}
          >
            <CCLExplore className="story" label={link.title} cardId={'card' + Number(i + 2)} cta={link.cta} image={link.image} expandedContent={true}></CCLExplore>
          </span>
        ))}
      </div>

      <div className="openStories">
        {stories2.map((story, x) => (
          <React.Fragment key={story.title}>{storyContent(story.expanded, x + 2)}</React.Fragment>
        ))}
      </div>
    </div>

    <div className="mobile-stories">
      <div className="stories">
        <span
          onClick={() => {
            toggleStory(5);
          }}
        >
          <CCLExplore className="story" label={stories[0].title} cardId={'card' + 5} cta={stories[0].cta} image={stories[0].image} expandedContent={true}></CCLExplore>
        </span>
        {storyContent(stories[0].expanded, 5)}

        <span
          onClick={() => {
            toggleStory(6);
          }}
        >
          <CCLExplore className="story" label={stories[1].title} cardId={'card' + 6} cta={stories[1].cta} image={stories[1].image} expandedContent={true}></CCLExplore>
        </span>
        {storyContent(stories[1].expanded, 6)}

        <span
          onClick={() => {
            toggleStory(7);
          }}
        >
          <CCLExplore className="story" label={stories2[0].title} cardId={'card' + 7} cta={stories2[0].cta} image={stories2[0].image} expandedContent={true}></CCLExplore>
        </span>
        {storyContent(stories2[0].expanded, 7)}

        <span
          onClick={() => {
            toggleStory(8);
          }}
        >
          <CCLExplore className="story" label={stories2[1].title} cardId={'card' + 8} cta={stories2[1].cta} image={stories2[1].image} expandedContent={true}></CCLExplore>
        </span>
        {storyContent(stories2[1].expanded, 8)}
      </div>
    </div>

    <CardSlider {...cards} />
    <JourneyForm />
  </Layout>
);

export default Individuals;
