import React from 'react';
import PropType from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './cardSlider.scss';

const CardSlider = props => {

  let sliderSettings = {
    dots: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    className: 'card-carousel',
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 745,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  if (!props[0].header) {
    return null;
  }

  let cardRow = props[0];

  return (
    <div className="ccl-cardslider">
      <h2 className="ccl-slider-title">{cardRow.header}</h2>
      <p className="ccl-slider-sub">{cardRow.subheader}</p>
      <Slider {...sliderSettings}>
        {cardRow.cards.map(card => (
          <div className="ccl-card" key={card.title}>
            <h3 className="card-title">{card.title}</h3>
            <a className="ccl-yellow-hover" href={card.link} target="_blank">
              {card.label}
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

CardSlider.propType = {
  header: PropType.string.isRequired,
  subheader: PropType.string.isRequired,
  cards: PropType.array.isRequired
};

export default CardSlider;