import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import CCLButton from './cclButton';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import logo from '../images/ccl-logo.png';
import './footer.scss';

library.add(fab);

const Footer = ({ siteTitle }) => (
  <footer id="ccl-footer">
    <div id="top-footer">
      <div className="ccl-social">
        <div>
          <a href="http://twitter.com/CCLdotORG" target="_blank">
            <FontAwesomeIcon icon={['fab', 'twitter']} color="black" size="lg" />
          </a>
          <a href="https://www.instagram.com/ccldotorg/" target="_blank">
            <FontAwesomeIcon icon={['fab', 'instagram']} color="black" size="lg" />
          </a>
          <a href="https://www.linkedin.com/company/center-for-creative-leadership/" target="_blank">
            <FontAwesomeIcon icon={['fab', 'linkedin']} color="black" size="lg" />
          </a>
          <a href="https://www.facebook.com/CenterforCreativeLeadership/" target="_blank">
            <FontAwesomeIcon icon={['fab', 'facebook']} color="black" size="lg" />
          </a>
          <a href="http://www.youtube.com/ccldotorg" target="_blank">
            <FontAwesomeIcon icon={['fab', 'youtube']} color="black" size="lg" />
          </a>
        </div>
        <div id="footer-newsletter">
          <h4>Subscribe to our Newsletters</h4>
          <a href="https://www.ccl.org/subscribe/" target="_blank">
            <CCLButton label="Subscribe" type="submit" style="primary"></CCLButton>
          </a>
        </div>
      </div>

      <div className="footer-logo">
        <a href="https://www.ccl.org" target="_blank">
          <img src={logo} alt="Center for Creative Leadership" />
        </a>
        <a href="https://www.ccl.org" target="_blank">Visit us at ccl.org</a>
      </div>
    </div>
    <div id="footer-legal">
      <a href="https://www.ccl.org" className="footer-mobile-logo" target="_blank">
        <img src={logo} alt="Center for Creative Leadership" />
        <div>Visit us at ccl.org</div>
      </a>
      <span>&copy; 2020 Center for Creative Leadership. All rights reserved.</span>
      <a href="https://www.ccl.org/privacypolicy/" target="_blank">
        Privacy Policy
      </a>
      <a href="https://www.ccl.org/privacypolicy/" target="_blank">
        Cookie Policy
      </a>
      <a href="https://www.ccl.org/privacypolicy/" target="_blank">
        Terms &amp; Conditions
      </a>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {
  siteTitle: `Home`
};

export default Footer;
