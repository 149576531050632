/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, noFollowIndexed }) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <meta name="description" content={metaDescription} />
      <meta property="og:image:alt" content={meta.imgAlt} />
      {
        noFollowIndexed && <meta name="robots" content="noindex, nofollow"></meta>
      }
      <meta name="com.silverpop.brandeddomains" content="www.pages04.net,ccl.org,cclorg.wpengine.com,innovation.ccl.org,insights.ccl.org,leadershipneverstops.org,myccl.ccl.org,programs.ccl.org,solutions.ccl.org,www.ccl.org,www.leadershipneverstops.org" />
      <script src="https://www.sc.pages04.net/lp/static/js/iMAWebCookie.js?19b6541-1241544b01b-beb2abcbc80381a2c00685eab367e4a7&h=www.pages04.net" type="text/javascript"></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  noFollowIndexed: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.object,
  title: PropTypes.string.isRequired,
  noFollowIndexed: PropTypes.bool,
}

export default SEO
