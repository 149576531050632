import PropTypes from "prop-types"
import React, { Component } from 'react'
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import logo from '../images/nav-logo.png';
import './header.scss';

export class Header extends Component {

  static propTypes = {
    siteTitle: PropTypes.string,
  }
  
  static defaultProps = {
    siteTitle: `Home`,
  }

  render() {
    return (
      <header id="ccl-header">
        <div id="mobile-menu">
          <Menu right width={'100%'} overlayClassName={'mobile-overlay'} burgerButtonClassName={'burger-btn'}>
            <Link to={`/individuals`}>individuals</Link>
            <Link to={`/organizations`}>organizations</Link>
            <Link to={`/communities`}>communities</Link>
            <Link to="#ccl-journey-form">contact us</Link>
            <a href="https://www.ccl.org/subscribe" target="_blank">newsletter</a>
            <div className="ccl-social">
              <a href="http://twitter.com/CCLdotORG" target="_blank">
                <FontAwesomeIcon icon={['fab', 'twitter']} color="white" size="lg" />
              </a>
              <a href="https://www.instagram.com/ccldotorg/" target="_blank">
                <FontAwesomeIcon icon={['fab', 'instagram']} color="white" size="lg" />
              </a>
              <a href="https://www.linkedin.com/company/8591" target="_blank">
                <FontAwesomeIcon icon={['fab', 'linkedin']} color="white" size="lg" />
              </a>
              <a href="http://www.facebook.com/pages/Center-for-Creative-Leadership/16501652102" target="_blank">
                <FontAwesomeIcon icon={['fab', 'facebook']} color="white" size="lg" />
              </a>
              <a href="http://www.youtube.com/ccldotorg" target="_blank">
                <FontAwesomeIcon icon={['fab', 'youtube']} color="white" size="lg" />
              </a>
            </div>
          </Menu>
        </div>
        <div id="nav-menu">
          <a href={`/`} id="nav-logo">
            <img src={logo} alt="Center for Creative Leadership" />
          </a>
          <div id="dt-menu">
            <nav>
              <Link to={`/individuals`} activeClassName="active">
                individuals
              </Link>
              <Link to={`/organizations`} activeClassName="active">
                organizations
              </Link>
              <Link to={`/communities`} activeClassName="active">
                communities
              </Link>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}

export default Header