const list = [
  { value: 'I am the main decision-maker', label: 'I am the main decision-maker' },
  { value: 'I share the decision, along with others', label: 'I share the decision, along with others' },
  { value: 'I am researching options for the decision making team', label: 'I am researching options for the decision making team' },
  { value: 'I am not involved', label: 'I am not involved' },
  { value: 'I am an external/independent consultant', label: 'I am an external/independent consultant' }
];

module.exports = {
  list: list
};
