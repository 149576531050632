const list = [
  { value: 'Less than 500 employees', label: 'Less than 500 employees' },
  { value: '500 to 999 employees', label: '500 to 999 employees' },
  { value: '1000 to 4999 employees', label: '1000 to 4999 employees' },
  { value: '5000 to 9999 employees', label: '5000 to 9999 employees' },
  { value: '10000 to 14999 employees', label: '10000 to 14999 employees' },
  { value: '15000 to 29999 employees', label: '15000 to 29999 employees' },
  { value: '30000 to 49999 employees', label: '30000 to 49999 employees' },
  { value: '50000 or more employees', label: '50000 or more employees' }
];

module.exports = {
  list: list
};