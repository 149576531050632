import React from 'react';
import PropType from 'prop-types';
import './cclLeadspace.scss';

const CCLLeadSpace = props => {
  let copy = props.copy.split('  ');
  let bgStyle = {
    backgroundImage: 'url(' + props.image + ')',
    backgroundPosition: props.backgroundAlginRight ? 'right' : 'center',
  };

  return (
    <div className="ccl-leadspace" style={bgStyle} role="img" aria-label={props.alt}>
      {/* <img src={props.image} alt="home 8" /> */}
      <div className="ccl-leadspace-content">
        <div className="ccl-leadspace-title">
          {copy.map(sentence => (
            <h1 key={sentence}>{sentence}</h1>
          ))}
        </div>
      </div>
    </div>
  );
};

CCLLeadSpace.propType = {
  image: PropType.string.isRequired,
  copy: PropType.string.isRequired
};

export default CCLLeadSpace;
