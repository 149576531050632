import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import CCLButton from './cclButton';
import Countries from '../constants/countries';
import interestIn from '../constants/interests';
import companySize from '../constants/companySize';
import roleSolutions from '../constants/roleSolutions';
import userRoles from '../constants/roles';
import './journeyForm.scss';

const JourneyForm = (props) => {
  let [getUpdates, setGetUpdates] = useState(false);
  let [showThanks, setShowThanks] = useState(false);
  let [invalidForm, setInvalidForm] = useState(false);
  let timestamp = moment().format('MM/DD/YYYY');
  let utms = {};

  const isObjEmpty = (obj) => {
    if (!obj)
      return true;
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  const extractHostname = (url) => {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    }
    else {
      hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  const availableParams = ['utm_source', 'utm_medium', 'utm_content', 'utm_term', 'utm_campaign', 'referrer'];

  const filterParams = (params) => {
    return params ? Object.keys(params)
      .filter(key => availableParams.includes(key))
      .reduce((obj, key) => {
        obj[key] = params[key];
        return obj;
      }, {}) : {};
  }

  const getParams = (url) => {
    let params = {};
    let parser = document.createElement('a');
    parser.href = url;
    let query = parser.search.substring(1);
    let vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      if (vars[i]) {
        let pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
    }

    const referrer = document.referrer;
    const domainReferrer = extractHostname(referrer);
    const currentSiteDomain = extractHostname(window.location.href);
    if (referrer.startsWith('https://www.facebook.com') || referrer.startsWith('https://l.facebook.com') || referrer.startsWith('https://www.linkedin.com') || referrer.startsWith('https://www.instagram.com') || referrer.startsWith('https://www.bing.com') || referrer.startsWith('https://www.google.com') || referrer.startsWith('https://www.pinterest.com')) {
      if (isObjEmpty(params)) {
        return {
          utm_source: domainReferrer,
          utm_medium: referrer.startsWith('https://www.bing.com') || referrer.startsWith('https://www.google.com') ? 'organic' : 'social',
        };
      }
      if (referrer.startsWith('https://l.facebook.com')) {
        return {
          utm_source: domainReferrer,
          utm_medium: 'social',
        };
      }
      if (referrer.startsWith('https://www.google.com') && params && params.gclid) {
        return {
          utm_source: domainReferrer,
          utm_medium: 'cpc',
          utm_content: params.matchtype,
          utm_term: params.keyword,
        }
      }
      return filterParams(params);
    }
    if (referrer && referrer.length > 0 && currentSiteDomain !== domainReferrer) {
      if (params & params.gclid) {
        return {
          utm_source: params.utm_source,
          utm_medium: params.utm_medium,
          utm_campaign: params.utm_campaign,
        };
      }
      return {
        utm_source: domainReferrer,
        utm_medium: 'referral',
      }
    }
    if (isObjEmpty(params))
      return {};
    return params;
  };

  const getCookie = name => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  useEffect(() => {
    const params = getParams(window.location.search);

    const cookieUtmParams = JSON.parse(getCookie('utm_params'));

    if (!isObjEmpty(params) && isObjEmpty(cookieUtmParams)) {
      utms = { ...params, referrer: document.referrer };
      document.cookie = 'utm_params=' + JSON.stringify({ ...params, referrer: document.referrer }) + '; ' + document.cookie;
    } else {
      utms = filterParams(cookieUtmParams);
    }
  });

  const handleFormSubmit = event => {
    console.log(utms);
    event.preventDefault();
    let formData = {}
    let isInvalid = false;

    const inputs = Array.prototype.slice.call(document.querySelectorAll(".ccl-input"), 0);

    for (let index = 0; index < inputs.length; index++) {
      const input = inputs[index];
      if (!input.value || input.selectedIndex < 1) {
        input.classList.add('invalid');
        isInvalid = true;
        continue;
      } else {
        input.classList.remove('invalid');
        switch (input.id) {
          case 'contact_first_name':
            formData.contact_first_name = input.value;
            break;
          case 'contact_last_name':
            formData.contact_last_name = input.value;
            break;
          case 'Email':
            formData.Email = input.value;
            break;
          case 'contact_phone':
            formData.contact_phone = input.value;
            break;
          case 'organization':
            formData.organization = input.value;
            break;
          case 'contact_country':
            formData.contact_country = Countries.list[input.selectedIndex - 1].value;
            break;
          case 'ccl_interested_in':
            formData.ccl_interested_in = interestIn.list[input.selectedIndex - 1].value;
            break;
          case 'ccl_size_of_organization':
            formData.ccl_size_of_organization = companySize.list[input.selectedIndex - 1].value;
            break;
          case 'ccl_level_in_organization':
            formData.ccl_level_in_organization = userRoles.list[input.selectedIndex - 1].value;
            break;
          case 'organization_role':
            formData.organization_role = roleSolutions.list[input.selectedIndex - 1].value;
            break;
          default:
            break;
        }
      }
    }

    setInvalidForm(isInvalid);

    if (!isInvalid) {
      formData.campaign_track = props.formName || 'lns-contact';
      formData.interest_code = props.interestCode || 'FORMPROMOCONTACTLNS';

      formData.utm_campaign = utms.utm_campaign;
      formData.utm_medium = utms.utm_medium;
      formData.utm_source = utms.utm_source;
      formData.utm_content = utms.utm_content;
      formData.utm_term = utms.utm_term;
      formData.referrer = utms.referrer;

      formData.formSourceName = 'StandardForm';
      if (getUpdates) {
        formData.consent_date_stamp = timestamp;
        formData.leading_effectively = 'Yes';
        formData.global_subscription = 'Yes';
      } else {
        formData.leading_effectively = 'No';
        formData.global_subscription = 'No';
      }

      formData.device_type = navigator.userAgent;
      formData.landing_page = 'https://www.leadershipneverstops.org/';
      formData.page_form_completed = window.location.href;

      submitFullForm(formData);
    }
  }

  const submitFullForm = formData => {
    const url = formData.campaign_track == 'lns-contact' ? 'https://ccl-form-api.herokuapp.com/journey' : 'https://ccl-form-api.herokuapp.com/' + formData.campaign_track;
    axios({
      method: 'POST',
      url,
      data: formData
    })
      .then(function (response) {
        //handle success
        setShowThanks(true);
        console.log(response);
        window.dataLayer.push({
          'event': 'formSubmit',
          'gtm.elementId': "Journey Form"
        });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  return (
    <div className="ccl-journey-form" id="ccl-journey-form">
      {!showThanks && (
        <div id="journey-form-view">
          <div id="journey-title">
            <h2>Now, let’s talk about you.</h2>
            <p>{props.description || 'Contact CCL to talk more about your needs.'}</p>
            <p className="required-title">All fields are required.</p>
            {invalidForm && (
              <p id="journey-error" className="ccl-form-add-required">
                Please add the required fields
              </p>
            )}
          </div>
          <div className="ccl-formview">
            <form id="journeyForm">
              <div className="form-item">
                <label htmlFor="contact_first_name">First name:</label>
                <input type="input" name="contact_first_name" className="ccl-input" id="contact_first_name" placeholder="First name" />
              </div>
              <div className="form-item">
                <label htmlFor="contact_last_name">Last name:</label>
                <input type="input" name="contact_last_name" className="ccl-input" id="contact_last_name" placeholder="Last name" />
              </div>
              <div className="form-item">
                <label htmlFor="Email">Email:</label>
                <input type="email" name="Email" id="Email" className="ccl-input" placeholder="Enter your email address" />
              </div>
              <div className="form-item">
                <label htmlFor="contact_phone">Phone:</label>
                <input type="input" name="contact_phone" className="ccl-input" id="contact_phone" placeholder="Phone number" />
              </div>
              <div className="form-item">
                <label htmlFor="contact_country">Country:</label>
                <select as="select" name="contact_country" className="ccl-input" id="contact_country">
                  <option className="option-placeholder">Select country</option>
                  {Countries.list.map(country => (
                    <option value={country.value.toUpperCase()} key={country.value}>
                      {country.value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-item">
                <label htmlFor="ccl_interested_in">Interested in:</label>
                <select as="select" name="ccl_interested_in" className="ccl-input" id="ccl_interested_in">
                  <option className="option-placeholder">Select intererest</option>
                  {interestIn.list.map(interest => (
                    <option value={interest.value} key={interest.value}>
                      {interest.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-item">
                <label htmlFor="organization">Company/Organization:</label>
                <input type="input" name="organization" className="ccl-input" id="organization" placeholder="Company/Organization" />
              </div>
              <div className="form-item">
                <label htmlFor="ccl_size_of_organization">Organization Size:</label>
                <select as="select" name="ccl_size_of_organization" className="ccl-input" id="ccl_size_of_organization">
                  <option className="option-placeholder">Select size</option>
                  {companySize.list.map(size => (
                    <option value={size.value} key={size.value}>
                      {size.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-item">
                <label htmlFor="ccl_level_in_organization">Role in Organization:</label>
                <select as="select" name="ccl_level_in_organization" className="ccl-input" id="ccl_level_in_organization">
                  <option className="option-placeholder">Select role</option>
                  {userRoles.list.map(role => (
                    <option value={role.value} key={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-item">
                <label htmlFor="organization_role">Role in Selecting Leadership Solutions:</label>
                <select as="select" name="organization_role" className="ccl-input" id="organization_role">
                  <option className="option-placeholder">Select role</option>
                  {roleSolutions.list.map(solution => (
                    <option value={solution.value} key={solution.value}>
                      {solution.label}
                    </option>
                  ))}
                </select>
              </div>
              <input type="hidden" name="campaign_track" data={props.formName || "lns-contact"} value={props.formName || "lns-contact"}></input>
              <input type="hidden" name="interest_code" data={props.interestCode || "FORMPROMOCONTACTLNS"} value={props.interestCode || "FORMPROMOCONTACTLNS"}></input>
              <input type="hidden" name="consent_date_stamp" value={timestamp}></input>
              <input type="hidden" name="utm_campaign" value={utms.utm_campaign}></input>
              <input type="hidden" name="utm_medium" value={utms.utm_medium}></input>
              <input type="hidden" name="utm_source" value={utms.utm_source}></input>
              <input type="hidden" name="formSourceName" value="StandardForm"></input>
              <input type="hidden" name="sp_exp" value="yes"></input>
              <div className="form-end">
                <div className="form-updates">
                  <input
                    type="checkbox"
                    name="updates"
                    id="ccl-updates"
                    checked={getUpdates}
                    onChange={() => {
                      setGetUpdates(getUpdates ? false : true);
                    }}
                  />
                  <label htmlFor="ccl-updates" className="ccl-update-label">
                    Yes, I’d like regular updates on thought leadership content, industry insights, and upcoming events from CCL. I understand I can opt out or change my subscription at any time
                    through CCL’s{' '}
                    <a href="https://www.ccl.org/preference-center/" target="_blank">
                      Email Preference Center.
                    </a>
                  </label>
                </div>

                <div className="form-submit">
                  <p>By submitting this form, you acknowledge that the Center for Creative Leadership (CCL) will use this information to contact you.</p>
                  <CCLButton label={props.btnTxt || "Request Information"} onClick={e => handleFormSubmit(e)} type="submit" style="primary" id="ccl-journey-form" arrow="true"></CCLButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {showThanks && (
        <div className="form-thanks">
          <h3>Thank you!</h3>
          <p>A representative from the Center for Creative Leadership will be reaching out to you shortly.</p>
        </div>
      )}
    </div>
  );
};

export default JourneyForm;
